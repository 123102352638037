import { Box, Card, Pagination, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import DialogConfirm from 'components/DialogConfirm';
import FloatingButton from 'components/FloatingButton';
import ListSkeleton from 'components/Skeleton/ListSkeleton';
import React, { useEffect, useState } from 'react';
import { get } from 'utils/api';

const API_URL = process.env.REACT_APP_API_URL;

const CustomerFeedback = () => {
  const [loading, setLoading] = useState(false);
  const [customerFeedbackData, setCustomerFeedbackData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const getCustomerFeedback = async (page = 1) => {
    setLoading(true);
    const response = await get(
      `${API_URL}/admin/customer-feedback?page=${page}&per_page=10`
    );
    setCustomerFeedbackData(response?.data?.data?.feedback_info || []);
    setTotalPages(response?.data?.data?.total_pages || 1);
    setCurrentPage(page);
    setLoading(false);
  };

  useEffect(() => {
    getCustomerFeedback(currentPage);
  }, [currentPage]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <Card>
      <Typography variant="h5" sx={{ fontWeight: 600 }}>
        Customer Feedback
      </Typography>
      {loading ? (
        <ListSkeleton />
      ) : (
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Full Name</TableCell>
                <TableCell>Email Address</TableCell>
                <TableCell>Feedback Rating</TableCell>
                <TableCell>Feedback</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {customerFeedbackData.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    No Data
                  </TableCell>
                </TableRow>
              )}
              {customerFeedbackData.map((item, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{item?.user_info?.name || "-"}</TableCell>
                  <TableCell>{item?.user_info?.email || "-"}</TableCell>
                  <TableCell>{item?.rating || "-"}</TableCell>
                  <TableCell>{item?.message || "-"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            sx={{ mt: 2 }}
          />
        </Box>
      )}

     
    </Card>
  );
};

export default CustomerFeedback;
