import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Grid,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { get } from "utils/api";
import SelectField from "components/SelectField";
import ListSkeleton from "components/Skeleton/ListSkeleton";
const API_URL = process.env.REACT_APP_API_URL;

const Activelog = () => {
  const [activelog, setActivelog] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [filter, setFilter] = useState({
    keyword: "",
    page: 1,
    filterBy: "search", // Default filter option
  });

  useEffect(() => {
    AllActiveLogApi();
    // eslint-disable-next-line
  }, [filter]);

  const AllActiveLogApi = async () => {
    setLoading(true);
    try {
      const result = await get(
        `${API_URL}/admin/activity-log?page=${filter.page
        }&per_page=10&search_value=${filter.filterBy || ""}`
      );
      setActivelog(result?.data?.data?.Activity_log);
      setPageCount(Math.ceil(result?.data?.data?.total_records / 10));
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleChangeFilter = async ({ target }) => {
    const { name, value } = target;
    const params = {
      ...filter,
      [name]: value,
      page: 1,
    };

    setFilter(params);
  };

  function formatType(item) {
    if (item?.log_info?.type) {
      // Split the text into words
      const words = item.log_info?.type.split("_");

      // Capitalize the first letter of each word
      const formattedText = words
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");

      return formattedText;
    } else {
      // If item?.type is undefined or null, return "-"
      return "-";
    }
  }

  const handleChangePagination = (e, val) => {
    setFilter({
      ...filter,
      page: val,
    });
  };

  return (
    <>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {/* Add the filter dropdown */}
            <SelectField
              label="Filter By"
              name="filterBy"
              value={filter.filterBy}
              onChange={handleChangeFilter}
            >
              <MenuItem value="search">Search</MenuItem>
              {/* <MenuItem value="personal_email">Personal Email</MenuItem>
            {/* <MenuItem value="mobile_number">Mobile Number</MenuItem> */}
            </SelectField>
          </Grid>
          {/* Add other filter options as needed */}
        </Grid>
        {loading ? (
          <div style={{ marginTop: "10px" }}>
            <ListSkeleton />
          </div>
        ) : (
          <Box>
            {activelog?.length !== 0 ? (
              <>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Search</TableCell>
                      <TableCell>Source</TableCell>
                      <TableCell>Not Found</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {activelog?.map((item, i) => (
                        <TableRow key={i}>
                          <TableCell>
                            {item?.log_info?.search_value || "-"}
                          </TableCell>
                          <TableCell>{item?.log_info?.source || "-"}</TableCell>
                          <TableCell>{formatType(item) || "-"}</TableCell>
                        </TableRow>
                      ))}
                    </>
                  </TableBody>
                </Table>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Pagination
                    sx={{ mt: 1 }}
                    page={filter?.page}
                    count={pageCount}
                    onChange={handleChangePagination}
                  />
                </div>
              </>
            ) : (
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                No Data
              </div>
            )}
          </Box>
        )}
      </Card>
    </>
  );
};

export default Activelog;
