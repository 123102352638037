import React from "react";
import { Route, Switch } from "react-router-dom";
import People from "screens/People";
import User from "screens/User";
import MainLayout from "./layouts/MainLayout";
import AuthLayout from "./layouts/AuthLayout";
import Login from "./screens/Auth/Login";
import Dashboard from "./screens/Dashboard";
import Setting from "./screens/Setting";
import CreateUser from "screens/User/Create";
import CreatePeople from "screens/People/Create";
import Feedback from "screens/Feedback";
import Bug from "screens/Bug";
import Activelog from "screens/Activelog";
import CreativeAi from "screens/CreativeAI";
import Category from "screens/Category";
import SubCategory from "screens/SubCategory";
import CreateCategory from "screens/Category/components/Create";
import CreateSubCategory from "screens/SubCategory/components/Create";
import Log from "screens/log";
import Payments from "screens/Payments";
import Plan from "screens/Payments/Plan";
import CreatePlan from "screens/Payments/components/Create";
import SubScriptionForm from "screens/Payments/components/Form/SubScriptionForm";
import PlanCard from "screens/PlanCard";
import CreatePlanCard from "screens/PlanCard/components/Create";
import PaymentList from "screens/PaymentList";
import RedeemCode from "screens/RedeemCode";
import CustomerFeedback from "screens/CustomerFeedback";

const PrivateRoutes = [
  { path: "/app/people", component: People },
  { path: "/app/people/create", component: CreatePeople },
  { path: "/app/people/update/:id", component: CreatePeople },

  { path: "/app/user", component: User },
  { path: "/app/user/create", component: CreateUser },
  { path: "/app/user/update/:id", component: CreateUser },

  { path: "/app/feedback", component: Feedback },
  { path: "/app/bug", component: Bug },

  { path: "/app/setting", component: Setting },

  { path: "/app/payments", component: Payments },
  { path: "/app/payments/plan/:id", component: Plan },
  { path: "/app/payments/create", component: CreatePlan },
  { path: "/app/payments/plan/:id/subscription", component: SubScriptionForm },

  { path: "/app/dashboard", component: Dashboard },

  { path: "/app/redeem-code", component: RedeemCode },

  { path: "/app/payment-list", component: PaymentList },

  { path: "/app/palncard", component:PlanCard },
  { path: "/app/palncard/create/:id", component:CreatePlanCard },
  { path: "/app/palncard/create", component:CreatePlanCard },

  { path: "/app/active-log", component: Activelog },

  { path: "/app/creative-ai", component: CreativeAi },

  { path: "/app/category", component: Category },
  { path: "/app/category/create", component: CreateCategory },
  { path: "/app/category/update/:id", component: CreateCategory },

  { path: "/app/log", component: Log },

  { path: "/app/subcategory", component: SubCategory },
  { path: "/app/subcategory/create", component: CreateSubCategory },
  { path: "/app/subcategory/update/:id", component: CreateSubCategory },

  { path: "/app/customer-feedback", component: CustomerFeedback },

];

export default function Routes() {
  return (
    <Switch>
      <Route exact={true} path={PrivateRoutes.map((item) => item.path)}>
        <MainLayout>
          <Switch>
            {PrivateRoutes.map((item, i) => (
              <Route
                key={i}
                exact
                path={item.path}
                component={item.component}
              />
            ))}
          </Switch>
        </MainLayout>
      </Route>

      <Route exact={true} path={["/"]}>
        <AuthLayout>
          <Switch>
            <Route exact path="/" component={Login} />
          </Switch>
        </AuthLayout>
      </Route>
    </Switch>
  );
}
