import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  Pagination,
  TableHead,
  TableRow,
} from "@mui/material";
import Typography from "@mui/material/Typography";
// import FloatingButton from "components/FloatingButton";
// import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GeneralSelector, PeopleSelector } from "selectors";
import { PeopleActions } from "slices/actions";
import DialogConfirm from "components/DialogConfirm";
import { toast } from "react-toastify";
// import { Edit } from "@mui/icons-material";
import { get } from "utils/api";
const API_URL = process.env.REACT_APP_API_URL;
export default function Feedback() {
  // const history = useHistory();
  const dispatch = useDispatch();
  const [feedbacks, setFeedbacks] = useState();

  const totalCount = feedbacks?.data?.data?.total_records || 0;
  const pageCount = Math.ceil(totalCount / 10);

  const success = useSelector(
    GeneralSelector.success(PeopleActions.deletePeople.type)
  );

  const [selected, setSelected] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [filter, setFilter] = useState({
    keyword: "",
    page: 1,
  });

  const AllPeopleApi = async () => {
    try {
      const result = await get(
        `${API_URL}/admin/feedback?type=feedback&page=${filter.page}&per_page=10`
      );
      console.log(result);
      setFeedbacks(result);

      if (success) {
        setConfirmDelete(false);
        setSelected(null);

        toast.success(`${success?.message ?? "Success"}`, {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
        });

        const result = await get(
          `${API_URL}/admin/feedback?type=feedback&page=${filter.page}&per_page=10`
        );
        setFeedbacks(result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    AllPeopleApi();
    // eslint-disable-next-line
  }, [success, filter]);

  useEffect(() => {
    if (success) {
      setConfirmDelete(false);
      setSelected(null);

      toast.success(`${success?.message ?? "Success"}`, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });

      const result = get(
        `${API_URL}/admin/feedback?type=feedback&page=${filter.page}&per_page=10`
      );
      setFeedbacks(result);
    }
  }, [success, filter, dispatch]);

  const handleChangePagination = (e, val) => {
    setFilter({
      ...filter,
      page: val,
    });
  };

  const handleDelete = () => {
    dispatch(PeopleSelector.deletePeople(selected));
  };

  return (
    <Card>
      <Typography variant="h5" sx={{ fontWeight: 600, marginBottom: 5 }}>
        Feedback
      </Typography>

      {/* <Hidden smDown>
                <Filter filter={filter} onChange={handleChangeFilter} />
            </Hidden> */}

      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Experience</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feedbacks?.data?.data?.total_records === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={7}>
                  No Data
                </TableCell>
              </TableRow>
            )}
            {feedbacks?.data?.data?.Feedback?.map(
              (item, i) =>
                item.type === "feedback" && (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{item?.created_at.split(" ")[0]}</TableCell>
                    <TableCell>{item?.exp_message || "-"}</TableCell>
                    <TableCell>{item?.experience}</TableCell>
                    <TableCell>{item?.user ? item?.user.email : "-"}</TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>

        <Pagination
          sx={{ mt: 1 }}
          page={filter?.page}
          count={pageCount}
          onChange={handleChangePagination}
        />
      </Box>

      <DialogConfirm
        title="Delete Data"
        content="Are you sure want to delete this data?"
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onSubmit={handleDelete}
      />
    </Card>
  );
}
