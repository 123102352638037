import React, { useEffect, useState } from "react";
import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    Pagination,
    TableHead,
    TableRow,
    Hidden,
} from "@mui/material";
import Typography from "@mui/material/Typography";
// import FloatingButton from "components/FloatingButton";
import { useDispatch, useSelector } from "react-redux";
import Filter from "./Filter";
import { GeneralSelector } from "selectors";
import { PeopleActions } from "slices/actions";
import { toast } from "react-toastify";
import ListSkeleton from "../../components/Skeleton/ListSkeleton";
// import { Edit } from "@mui/icons-material";
import { get } from "utils/api";
const API_URL = process.env.REACT_APP_API_URL;


export default function Bug() {
    const dispatch = useDispatch();
    const [feedbacks, setFeedbacks] = useState();
    const totalCount = feedbacks?.data?.data?.total_records || 0;
    const pageCount = Math.ceil(totalCount / 10);

    const loading = useSelector(
        GeneralSelector.loader(PeopleActions.getPeoples.type)
    );
    const success = useSelector(
        GeneralSelector.success(PeopleActions.deletePeople.type)
    );


    const [filter, setFilter] = useState({
        keyword: "",
        page: 1,
    });


    const AllPeopleApi = async () => {
        try {
            const result = await get(`${API_URL}/admin/feedback?type=bug&par_page=10&page=${filter.page}&search_value=${filter.keyword}`);
            setFeedbacks(result);

            if (success) {

                toast.success(`${success?.message ?? "Success"}`, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                });

                const result = await get(`${API_URL}/admin/feedback?type=bug&par_page=10&page=${filter.page}`);
                setFeedbacks(result);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        AllPeopleApi();
        // eslint-disable-next-line
    }, [success, filter]);


    useEffect(() => {
        if (success) {

            toast.success(`${success?.message ?? "Success"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });

            const result = get(`${API_URL}/admin/feedback?type=bug&par_page=10&page=${filter.page}`);
            setFeedbacks(result);
        }
    }, [success, filter, dispatch]);

    const handleChangeFilter = async ({ target }) => {

        const { name, value } = target;
        const params = {
            ...filter,
            keyword: value,
            page: 1
        };

        if (value === -1) {
            delete params[name];
        }

        setFilter(params);
    };

    const handleChangePagination = (e, val) => {
        setFilter({
            ...filter,
            page: val,
        });
    };

    const formatBugTypes = (bugTypes) => {
        const bugTypeArray = bugTypes.split(', '); // Split the string into an array
        const formattedBugTypes = bugTypeArray.map((bugType) => {
            // Capitalize the first letter and add spaces
            return bugType.replace('_', ' ').replace(/\b\w/g, (c) => c.toUpperCase());
        });
        return formattedBugTypes.join(', '); // Join the formatted bug types with commas
    };




    return (
        <Card>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
                Bug
            </Typography>

            <Hidden smDown>
                <Filter filter={filter} onChange={handleChangeFilter} />
            </Hidden>

            {loading ? (
                <ListSkeleton />
            ) : (
                <Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Message</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Email</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {feedbacks?.data?.data?.total_records === 0 && (
                                <TableRow>
                                    <TableCell align="center" colSpan={7}>
                                        No Data
                                    </TableCell>
                                </TableRow>
                            )}
                            {feedbacks?.data?.data?.Feedback?.map((item, i) => (
                                item.type === "bug" &&
                                <TableRow
                                    key={i}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell>{item.created_at.split(' ')[0]}</TableCell>
                                    <TableCell>{item.bug_message || "-"}</TableCell>
                                    <TableCell>
                                        {/* {item.bug_type} */}
                                        {formatBugTypes(item.bug_type) || "-"}
                                    </TableCell>
                                    <TableCell>{item.email || "-"}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Pagination
                        sx={{ mt: 1 }}
                        page={filter?.page}
                        count={pageCount}
                        onChange={handleChangePagination}
                    />
                </Box>
            )}
        </Card>
    );
}
