import React, { useEffect, useState } from "react";
import { Box, Card, Grid, IconButton, Typography } from "@mui/material";
import Input from "components/Input";
import PropTypes from "prop-types";
import { PhotoCamera } from "@mui/icons-material";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

AccountSetting.propTypes = {
  formik: PropTypes.object,
};

export default function AccountSetting(props) {
  const [selectedImage, setSelectedImage] = useState(null);
  const { id } = useParams();
  const { formik, categoryImg } = props;

  useEffect(() => {
    if (categoryImg instanceof File) {
      // If categoryImg is a File (uploaded by the user)
      formik.setFieldTouched("categoryImg", false);
      formik.setFieldValue("categoryImg", categoryImg);

      const imageURL = URL.createObjectURL(categoryImg);
      setSelectedImage(imageURL);
    } else if (typeof categoryImg === "string") {
      // If categoryImg is a URL received from the server
      setSelectedImage(categoryImg);
    }
    // eslint-disable-next-line
  }, [categoryImg]);

  const handleImageChange = (e) => {
    const fileInput = e.target;
    const fileName = fileInput.value;
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

    if (!allowedExtensions.exec(fileName)) {
      alert(
        "Please select a valid image file with .jpg, .jpeg, or .png extension."
      );
      fileInput.value = "";
      return false;
    }

    const file = e.target.files[0]; // Get the selected file
    if (file) {
      // Reset the categoryImg field's touched state
      formik.setFieldTouched("categoryImg", false);

      // Set the selected image in your formik state or handle it as needed
      formik.setFieldValue("categoryImg", file); // Assuming your formik field is named "categoryImg"

      // Display a preview of the selected image
      const imageURL = URL.createObjectURL(file);
      setSelectedImage(imageURL);
    }
  };

  return (
    <Card style={{ position: "relative" }} sx={{ mb: 3 }}>
      <Typography variant="h5" sx={{ mb: 4 }}>
        {id ? "Update Category" : "Add Category"}
      </Typography>
      {
        <Grid container spacing={3}>
          <Grid item lg={6} xs={12}>
            <Input
              label="Name*"
              type="text"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helpertext={formik.touched.name && formik.errors.name}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <Input
              label="Description*"
              type="text"
              name="description"
              multiline
              maxRows={4}
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                Boolean(formik.touched.description) &&
                Boolean(formik.errors.description)
              }
              helpertext={
                formik.touched.description && formik.errors.description
              }
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <Input
              type="file"
              id="image-input"
              accept=".jpg, .jpeg, .png"
              name="categoryImg"
              style={{ display: "none" }}
              onChange={handleImageChange}
              error={
                Boolean(formik.touched.categoryImg) &&
                Boolean(formik.errors.categoryImg)
              }
              helpertext={
                formik.touched.categoryImg && formik.errors.categoryImg
              }
            />

            <Box
              display="flex"
              alignItems="start"
              flexDirection="column"
              textAlign="center"
            >
              <label htmlFor="image-input">
                <IconButton
                  color="primary"
                  aria-label="upload image"
                  component="span"
                >
                  <PhotoCamera fontSize="large" />
                </IconButton>
                <Typography variant="caption">Upload Image*</Typography>
              </label>
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="Selected Preview"
                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      }
    </Card>
  );
}
