import React, { useEffect, useState } from "react";
import AccountSettings from "./AccountSettings";
import { useFormik } from "formik";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import PageTitle from "components/PageTitle";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { get, post, put } from "utils/api";
import { toast } from "react-toastify";
import * as Yup from "yup";

const API_URL = process.env.REACT_APP_API_URL;

const CreatePlanCard = () => {
  const history = useHistory();
  const { id } = useParams();
  console.log(id, "id...");

  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState("");
  const [data, setData] = useState("");
  const [linkedin_extension, setLinkedinExtension] = useState();
  const [crm_integration, setCrmIntegration] = useState();

  useEffect(() => {
    getPlanCardSingle();
  }, []);

  const getPlanCardSingle = async () => {
    const response = await get(`${API_URL}/admin/plan-card/${id}`);
    setData(response?.data?.data);
  };

  console.log(data, "::sds:::");

  const handleLinkedinExtension = (e) => {
    const { checked } = e.target;
    setLinkedinExtension(checked);
  };

  const handleCrmIntegration = (e) => {
    const { checked } = e.target;

    setCrmIntegration(checked);
  };

  const validationSchema = Yup.object({
    totalPrice: Yup.number()
      .required("Total Price is required")
      .min(0, "Total Price must be a positive number"),
    planType: Yup.string().required("Plan Type is required"),
    period: Yup.string().required("Period is required"),
    superCredits: Yup.number()
      .required("Super Credits is required")
      .min(0, "Super Credits must be a positive number"),
    basicCredits: Yup.number()
      .required("Basic Credits is required")
      .min(0, "Basic Credits must be a positive number"),
    // creativeCredits: Yup.number()
    //   .required("Creative Credits is required")
    //   .min(0, "Creative Credits must be a positive number"),
    searchCredits: Yup.number()
      .required("Search Credits is required")
      .min(0, "Search Credits must be a positive number"),
  });

  const formik = useFormik({
    initialValues: {
      totalPrice: data?.total_price || "",
      planType: data?.plan_type || "",
      period: data?.billed_period || "monthly",
      superCredits: data?.super_credits || "",
      basicCredits: data?.basic_credits || "",
      // creativeCredits: data?.creative_credits || "",
      searchCredits: data?.search_credits || "",
      linkedin_extension: data?.linkedin_extension || false,
      crm_integration: data?.crm_integration || false,
    },

    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      id ? handleUpdate(values) : handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    setLoading(true);

    const params = {
      total_price: values?.totalPrice,
      plan_type: values?.planType,
      billed_period: values?.period,
      super_credits: values?.superCredits,
      basic_credits: values?.basicCredits,
      // creative_credits: values?.creativeCredits,
      search_credits: values?.searchCredits,
      linkedin_extension: values?.linkedin_extension,
      crm_integration: values?.crm_integration,
    };

    try {
      // If "id" does not exist, it means we are creating a new user
      const response = await post(`${API_URL}/admin/plan-cards`, params);
      if (response.status === 200) {
        setResData(response?.data?.data);
        // If the request is successful (status code 200), show a success toast
        toast.success(response?.data?.message);
        setLoading(false);
        history.push("/app/palncard");
      } else {
        // If there's an error in the API response, show an error toast with the response data message
        toast.error(
          response?.data?.message || "Failed to add plan Please try again later."
        );
        setLoading(false);
      }
    } catch (error) {
      // Handle errors if the API call fails
      toast.error(
        error.response.data.data
          ? error.response.data.data
          : "An error occurred. Please try again later."
      );
      setLoading(false);
    }
  };

  const handleUpdate = async (values) => {
    setLoading(true);
    console.log("update");
    const updatedFields = {};

    // Check each field and include it in updatedFields if it has changed
    if (values.totalPrice !== formik.initialValues.totalPrice) {
      updatedFields.total_price = values.totalPrice;
    }

    if (values.planType !== formik.initialValues.planType) {
      updatedFields.plan_type = values.planType;
    }

    // if (values.period !== formik.initialValues.period) {
    //   updatedFields.billed_period = values.period;
    //   updatedFields.total_price = values.totalPrice;
    // }

    // if (values.superCredits !== formik.initialValues.superCredits) {
    //   updatedFields.super_credits = values.superCredits;
    // }

    if (values.basicCredits !== formik.initialValues.basicCredits) {
      updatedFields.basic_credits = values.basicCredits;
    }

    // if (values.creativeCredits !== formik.initialValues.creativeCredits) {
    //   updatedFields.creative_credits = values.creativeCredits;
    // }

    if (values.searchCredits !== formik.initialValues.searchCredits) {
      updatedFields.search_credits = values.searchCredits;
    }

    if (values.linkedin_extension !== formik.initialValues.linkedin_extension) {
      updatedFields.linkedin_extension = values.linkedin_extension;
    }

    if (values.crm_integration !== formik.initialValues.crm_integration) {
      updatedFields.crm_integration = values.crm_integration;
    }

    try {
      // If "id" does not exist, it means we are creating a new user
      const response = await put(
        `${API_URL}/admin/plan-cards/${id} `,
        updatedFields
      );
      if (response.status === 200) {
        setData(response?.data?.data);
        // If the request is successful (status code 200), show a success toast
        toast.success(response?.data?.message);
        setLoading(false);
        history.push(`/app/palncard`);
      } else {
        // If there's an error in the API response, show an error toast with the response data message
        toast.error(
          response?.data?.message || "Failed to add plan Please try again later."
        );
        setLoading(false);
      }
    } catch (error) {
      // Handle errors if the API call fails
      toast.error(
        error.response.data.data
          ? error.response.data.data
          : "An error occurred. Please try again later."
      );
      setLoading(false);
    }
  };

  return (
    <Box>
      <PageTitle title={"Create Plan Card"} />

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item lg={12} sm={12} xs={12}>
            <AccountSettings
              formik={formik}
              handleCrmIntegration={handleCrmIntegration}
              handleLinkedinExtension={handleLinkedinExtension}
            />
            <Grid item lg={12} sm={12} xs={12} container gap={5}>
              <Grid
                item
                lg={2}
                sm={2}
                xs={2}
                container
                justifyContent="flex-end"
              >
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.push(`/app/palncard`);
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid
                item
                lg={2}
                sm={2}
                xs={2}
                container
                justifyContent="flex-end"
              >
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {!loading ? (
                    "Submit"
                  ) : (
                    <CircularProgress style={{ color: "white" }} size={25} />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default CreatePlanCard;
