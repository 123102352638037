import React from "react";
import { Box } from "@mui/material";
import PageTitle from "components/PageTitle";
import Widgets from "./components/Widgets";
import Charts from "./components/Charts";

export default function Dashboard() {
  return (
    <Box>
      <PageTitle title="Dashboard" />
      <Widgets />
      <Charts/>
    </Box>
  );
}
