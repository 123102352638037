import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Switch as MuiSwitch,
  Box,
} from "@mui/material";
import Input from "components/Input";
import React from "react";
const AccountSetting = (props) => {
  const {
    formik,
    planData,
    handleCrmIntegration,
    handleLinkedinExtension,
    handleIsVisible,
  } = props;

  return (
    <Card style={{ position: "relative" }} sx={{ mb: 3 }}>
      <Typography variant="h5" sx={{ mb: 4 }}>
        Add Subscription
      </Typography>
      {
        <Grid container spacing={3}>
          <Grid item lg={6} xs={12}>
            <Input
              label="Plan Type*"
              type="text"
              disabled={planData ? true : false}
              name="plan"
              value={formik.values.plan}
              onChange={formik.handleChange}
              error={formik.touched.plan && Boolean(formik.errors.plan)}
              helpertext={formik.touched.plan && formik.errors.plan}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <Input
              label="Price*"
              type="text"
              name="price"
              value={formik.values.price}
              onChange={formik.handleChange}
              multiline
              maxRows={4}
              error={
                Boolean(formik.touched.price) && Boolean(formik.errors.price)
              }
              helpertext={formik.touched.price && formik.errors.price}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <InputLabel>Period</InputLabel>
            <FormControl fullWidth>
              <Select
                name={`period`}
                value={formik.values.period}
                onChange={formik.handleChange}
              >
                <MenuItem value="MONTH">Monthly</MenuItem>
                <MenuItem value="YEAR">Annually</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item lg={6} xs={12}>
            <Input
              label="Super Credits*"
              type="text"
              name="superCredits"
              value={formik.values.superCredits}
              onChange={formik.handleChange}
              error={
                formik.touched.superCredits &&
                Boolean(formik.errors.superCredits)
              }
              helpertext={
                formik.touched.superCredits && formik.errors.superCredits
              }
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <Input
              label="Basic Credits*"
              type="text"
              name="basicCredits"
              value={formik.values.basicCredits}
              onChange={formik.handleChange}
              error={
                formik.touched.basicCredits &&
                Boolean(formik.errors.basicCredits)
              }
              helpertext={
                formik.touched.basicCredits && formik.errors.basicCredits
              }
            />
          </Grid>

          {/* <Grid item lg={6} xs={12}>
            <Input
              label="Creative Credits*"
              type="text"
              name="creativeCredits"
              value={formik.values.creativeCredits}
              onChange={formik.handleChange}
              error={
                formik.touched.creativeCredits &&
                Boolean(formik.errors.creativeCredits)
              }
              helpertext={
                formik.touched.creativeCredits && formik.errors.creativeCredits
              }
            />
          </Grid> */}

          <Grid item lg={6} xs={12}>
            <Input
              label="Search Credits*"
              type="text"
              name="searchCredits"
              value={formik.values.searchCredits}
              onChange={formik.handleChange}
              error={
                formik.touched.searchCredits &&
                Boolean(formik.errors.searchCredits)
              }
              helpertext={
                formik.touched.searchCredits && formik.errors.searchCredits
              }
            />
          </Grid>

          <Grid item lg={12} xs={12}>
            <Box
              sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Typography>Linkedin Extension</Typography>
              <MuiSwitch
                checked={formik.values.linkedin_extension || false}
                name="linkedin_extension"
                onChange={(e) => {
                  formik.handleChange(e);
                  handleLinkedinExtension(e);
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={12} xs={12}>
            <Box
              sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Typography>Crm Integration</Typography>
              <MuiSwitch
                checked={formik.values.crm_integration || false}
                name="crm_integration"
                onChange={(e) => {
                  formik.handleChange(e);
                  handleCrmIntegration(e);
                }}
              />
            </Box>
          </Grid>

          <Grid item lg={12} xs={12}>
            <Box
              sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Typography>is visible</Typography>
              <MuiSwitch
                checked={formik.values.is_visible || false}
                name="is_visible"
                onChange={(e) => {
                  formik.handleChange(e);
                  handleIsVisible(e);
                }}
              />
            </Box>
          </Grid>
        </Grid>
      }
    </Card>
  );
};

export default AccountSetting;
