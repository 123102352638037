import React, { useEffect, useState, useRef } from "react";
import io from "socket.io-client";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ScrollToBottom from "react-scroll-to-bottom";

// Move socket creation outside the component
// const socket = io("http://192.168.29.29:9002");
const socketBaseUrl = process.env.REACT_APP_BACKEND_SOCKET_BASE_URL;

// Move socket creation outside the component
const socket = io(socketBaseUrl);

console.log(socketBaseUrl, "socketBaseUrl");

const Log = () => {
  const scrollRef = useRef();
  const isSocketInitialized = useRef(false);
  const [logs, setLogs] = useState([]);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("connected from server");
      setIsConnected(true);
    });

    if (!isSocketInitialized.current) {
      // Initialize socket events only once
      socket.on("log", (data) => {
        console.log("Received custom event:", data);
        setLogs((prevLogs) => [...prevLogs, data]);
      });

      socket.emit("log", { message: "" });

      socket.on("disconnect", () => {
        console.log("Disconnected from server");
        setIsConnected(false);
      });

      isSocketInitialized.current = true;
    }

    // Clean up socket connection when component unmounts
    return () => {
      socket.disconnect();
    };
  }, []);

  const getColorBasedOnLogLevel = (log) => {
    if (log.includes("ERROR")) {
      return "red";
    } else if (log.includes("INFO")) {
      return "green";
    } else {
      return "yellow";
    }
  };

  return (
    <>
      <ScrollToBottom className="font-tarminal" ref={scrollRef}>
        <div
          className="font-tarminal"
          style={{
            background: "#000",
            height: "87vh",
            borderRadius: "10px",
            position: "relative",
            overflowY: "scroll",
            // Hide the scrollbar
            scrollbarWidth: "0px",
            scrollbarColor: "transparent transparent",
          }}
        >
          <div
            style={{
              paddingTop: "35px",
              paddingLeft: "15px",
              paddingRight: "15px",
              color: "#fff",
            }}
          >
            {isConnected ? (
              logs.length !== 0 ? (
                <div>
                  {logs.map((item, index) => {
                    const color = getColorBasedOnLogLevel(item);
                    return (
                      <p
                        className="font-tarminal"
                        style={{ color }}
                        key={index}
                      >
                        {">"} {item}
                      </p>
                    );
                  })}
                </div>
              ) : (
                "connecting..."
              )
            ) : (
              <p>Connection lost. Please wait for reconnection...</p>
            )}
          </div>
          <div style={{ position: "absolute", top: "5px", left: "5px" }}>
            <FiberManualRecordIcon style={{ color: "red" }} />
            <FiberManualRecordIcon style={{ color: "yellow" }} />
            {isConnected ? (
              <FiberManualRecordIcon style={{ color: "green" }} />
            ) : (
              <FiberManualRecordIcon style={{ color: "gray" }} />
            )}
          </div>
        </div>
      </ScrollToBottom>
    </>
  );
};

export default Log;
