import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import PageTitle from "components/PageTitle";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { get, post } from "utils/api";
import AccountSetting from "./AccountSetting";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

const API_URL = process.env.REACT_APP_API_URL;

export default function CreateCategory() {
    const history = useHistory();
    const [fetchData, setFetchData] = useState();
    const [loading, setLoading] = useState(false)
    const { id } = useParams();
    // eslint-disable-next-line


    const getCategory = async (id) => {
        if (id) {
            try {
                // If "id" does not exist, it means we are creating a new user
                const response = await get(`${API_URL}/admin/get-single-category?category_id=${id}`)
                if (response.status === 200) {
                    setFetchData(response?.data?.data)
                    // If the request is successful (status code 200), show a success toast
                    // toast.success("Category Added Successfully...");
                    // history.push('/app/category')
                    setLoading(false)
                } else {
                    // If there's an error in the API response, show an error toast with the response data message
                    toast.error(
                        response?.data || "Failed to generate responce. Please try again later."
                    );
                    setLoading(false)
                }
            } catch (error) {
                // Handle errors if the API call fails
                toast.error(
                    error.response.data.data
                        ? error.response.data.data
                        : "An error occurred. Please try again later."
                );
                setLoading(false)

            }
        }
    }

    useEffect(() => {
        getCategory(id)
    }, [id])


    const validationSchema = Yup.object({
        name: Yup.string().required("Name is required"),
        description: Yup.string().required("Description is required"),
        categoryImg: Yup.mixed().required("Image is required"),
    });


    const formik = useFormik({
        initialValues: {
            name: fetchData?.name || "",
            description: fetchData?.description || "",
            categoryImg: fetchData?.icon_url || "",
        },

        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    const handleSubmit = async (values) => {
        setLoading(true);
        const formData = new FormData();

        if (id) {
            formData.append("category_id", id)
            formData.append("name", values.name);
            formData.append("description", values.description);
            if (values.categoryImg instanceof File) {
                formData.append("icon_image", values.categoryImg);
            } else {
                // If it hasn't been changed, send an empty string
                formData.append("icon_image", "");
            }
        } else {
            // Append the fields to the FormData object
            formData.append("name", values.name);
            formData.append("description", values.description);
            formData.append("icon_image", values.categoryImg);
        }

        try {
            if (id) {
                // If "id" does not exist, it means we are creating a new user
                const response = await post(`${API_URL}/admin/update-category`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                if (response.status === 200) {
                    // If the request is successful (status code 200), show a success toast
                    toast.success("Updated Successfully...");
                    history.push('/app/category')
                    setLoading(false)
                } else {
                    // If there's an error in the API response, show an error toast with the response data message
                    toast.error(
                        response?.data || "Failed to generate responce. Please try again later."
                    );
                    setLoading(false)
                }
            } else {
                // If "id" does not exist, it means we are creating a new user
                const response = await post(`${API_URL}/admin/categories`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                if (response.status === 200) {
                    // If the request is successful (status code 200), show a success toast
                    toast.success("Category Added Successfully...");
                    history.push('/app/category')
                    setLoading(false)
                } else {
                    // If there's an error in the API response, show an error toast with the response data message
                    toast.error(
                        response?.data || "Failed to generate responce. Please try again later."
                    );
                    setLoading(false)
                }
            }
        } catch (error) {
            // Handle errors if the API call fails
            toast.error(
                error?.response?.data?.message
                    ? error?.response?.data?.message
                    : "An error occurred. Please try again later."
            );
            setLoading(false)

        }
    };


    return (
        <Box>
            <PageTitle title={id ? "Update Category" : "Add Category"} />

            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={1}>

                    <Grid item lg={12} sm={12} xs={12}>

                        <AccountSetting formik={formik} categoryImg={formik.values.categoryImg} />
                        <Grid item lg={12} sm={12} xs={12} container gap={5}>
                            <Grid item lg={2} sm={2} xs={2} container>
                                <Button
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { history.push('/app/category'); }}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item lg={2} sm={2} xs={2} container>
                                <Button
                                    fullWidth
                                    disabled={loading}
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    {!loading ? "Submit" :
                                        <CircularProgress style={{ color: "white" }} size={25} />}
                                </Button>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </form>

        </Box>
    );
}
