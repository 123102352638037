import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  Pagination,
  TableHead,
  TableRow,
  Hidden,
  IconButton,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import FloatingButton from "components/FloatingButton";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Filter from "./components/Filter";
import { GeneralSelector, PeopleSelector } from "selectors";
import { GeneralActions, PeopleActions } from "slices/actions";
import DialogConfirm from "components/DialogConfirm";
import { toast } from "react-toastify";
import ListSkeleton from "../../components/Skeleton/ListSkeleton";
import { Edit } from "@mui/icons-material";
import useDebounce from "useDebounce";

export default function People() {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector(PeopleSelector.getPeoples());
  const totalCount = data?.total_results || 0;
  const pageCount = Math.ceil(totalCount / 10);

  const loading = useSelector(
    GeneralSelector.loader(PeopleActions.getPeoples.type)
  );
  const success = useSelector(
    GeneralSelector.success(PeopleActions.deletePeople.type)
  );

  const [selected, setSelected] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [filter, setFilter] = useState({
    keyword: "",
    page: 1,
  });
  const debouncedInputValue = useDebounce(filter?.keyword, 1500);
  const filterPage = filter?.page
  const AllPeopleApi = () => {
    try {
      dispatch(
        PeopleActions.getPeoples({
          keyword: filter.keyword,
          page: filter.page,
        })
      );
      if (success) {
        setConfirmDelete(false);
        setSelected(null);

        toast.success(`${success?.message ?? "Success"}`, {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
        });

        // dispatch(GeneralActions.removeSuccess(PeopleActions.deletePeople.type));
        dispatch(PeopleActions.getPeoples(filter));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    AllPeopleApi();
    // eslint-disable-next-line
  }, [success  , debouncedInputValue]);

    const handleChangeFilter = async ({ target }) => {
      const { name, value } = target;
      const params = {
        ...filter,
        keyword: value,
        page: 1,
      };

      if (value === -1) {
        delete params[name];
      }

      setFilter(params);
    };
    const handleChangePagination = (e, val) => {
      setFilter({
        ...filter,
        page: val,
      });
      dispatch(PeopleActions.getPeoples({ ...filter, page: val }));
    };
  const handleDelete = () => {
    dispatch(PeopleActions.deletePeople(selected));
  };

  return (
    <Card>
      <Typography variant="h5" sx={{ fontWeight: 600 }}>
        People
      </Typography>

      <Hidden smDown>
        <Filter filter={filter} onChange={handleChangeFilter} />
      </Hidden>

      {loading ? (
        <ListSkeleton />
      ) : (
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Full Name</TableCell>
                <TableCell>Current Position</TableCell>
                <TableCell>Current company</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>LinkedIn URL</TableCell>
                <TableCell align="right">Option</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(data?.data) && data?.data?.length > 0 ? (
                data?.data.map((item, i) => {
                  console.log(item, "item.........");
                  return (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{item.full_name || "-"}</TableCell>
                      <TableCell>{item.job_title || "-"}</TableCell>
                      <TableCell>{item.job_company_name || "-"}</TableCell>

                      <TableCell>
                        {Array.isArray(item.emails) &&
                        item.emails.length !== 0 ? (
                          item.emails.map((emailItem, index) => (
                            <p key={index} className="">
                              {emailItem.type
                                ? emailItem.type
                                    .replace(/_/g, " ")
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")
                                : ""}{" "}
                              - {emailItem.address}
                            </p>
                          ))
                        ) : (
                          <p>No emails</p>
                        )}
                      </TableCell>

                      {/* <TableCell>
                        {Array.isArray(item.phone_numbers) &&
                        item.phone_numbers.length !== 0 ? (
                          item.phone_numbers.map((phoneNumber, index) => (
                            <p key={index}>{phoneNumber}</p>
                          ))
                        ) : (
                          <p>No Contact</p>
                        )}
                      </TableCell> */}
                      <TableCell>
                        {item?.phone_numbers?.map((phone_number, idx) => {
                          return <>{phone_number}</>;
                        })}
                      </TableCell>

                      <TableCell>
                        {item.linkedin_url ? (
                          <a
                            style={{ color: "blue" }}
                            href={`//${item.linkedin_url}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.linkedin_url}
                          </a>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() =>
                            history.push(`/app/people/update/${item?._id}`)
                          }
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    No Data
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <Pagination
            sx={{ mt: 1 }}
            page={filter?.page}
            count={pageCount}
            onChange={handleChangePagination}
          />
        </Box>
      )}

      {/* <FloatingButton onClick={() => history.push("/app/people/create")} /> */}

      <DialogConfirm
        title="Delete Data"
        content="Are you sure want to delete this data?"
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onSubmit={handleDelete}
      />
    </Card>
  );
}
