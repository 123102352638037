import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import PageTitle from "components/PageTitle";
import * as yup from "yup";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AccountSetting from "./components/Create/AccountSetting";
import { get, post } from "utils/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const API_URL = process.env.REACT_APP_API_URL;

export default function CreatePeople() {
  const history = useHistory();
  const [peopleData, setPeopleData] = useState();
  console.log(peopleData,"peopledata....")
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [emailData, setEmailData] = useState([
    {
      address: "",
      type: "personal",
    },
  ]);
  // eslint-disable-next-line
  const [phoneData, setPhoneData] = useState([""]);
  const { id } = useParams();

  

  const handleUserData = () => {
    setLoading(true);

    if (id) {
      get(`${API_URL}/admin/get-people-data/?elastic_id=${id}`)
        .then((data) => {
          setPeopleData(data?.data?.data);
          console.log(data ,"nnn")
         
           setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleUserData();
    // eslint-disable-next-line
  }, [id]);
console.log(id,"id...")

  const validationSchema = yup.object({
    // first_name: yup.string().required("First name is required"),
    // last_name: yup.string().required("Last name is required"),
    // linkedin_url: yup.string().required("Linkedin url is required"),
    // job_title: yup.string().required("Job title is required"),
    // job_company_name: yup.string().required("Job company name is required"),
    phone_numbers: yup
      .array()
      .of(
        yup
          .string()
          .matches(/^\+?[0-9]+$/, "Invalid phone number format")
          .required("Phone number is required")
      )
      .min(1, "At least one phone number is required"),
    emails: yup
      .array()
      .of(
        yup.object().shape({
          address: yup
            .string()
            .email("Invalid email format")
            .required("Email address is required"),
          type: yup
            .string()
            .oneOf([
              "personal",
              "professional",
              "current_professional",
              "work_email",
            ])
            .required("Email type is required"),
        })
      )
      .min(1, "At least one email is required"),
  });

  if (id) {
    validationSchema.fields = {};
  }

  const formik = useFormik({
    initialValues: {
      // first_name: peopleData ? peopleData?.first_name : "",
      // last_name: peopleData ? peopleData?.last_name : "",
      // linkedin_url: peopleData ? peopleData?.linkedin_url : "",
      // job_title: peopleData ? peopleData?.job_title : "",
      // job_company_name: peopleData ? peopleData?.job_company_name : "",
      phone_numbers:
        peopleData &&
        peopleData.phone_numbers &&
        peopleData.phone_numbers.length > 0
          ? peopleData.phone_numbers
          : phoneData,
      emails:
        peopleData && peopleData.emails && peopleData.emails.length > 0
          ? peopleData.emails
          : emailData,
    },

    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    const params = {
      // first_name: values?.first_name,
      // last_name: values?.last_name,
      // linkedin_url: values?.linkedin_url,
      // job_title: values?.job_title,
      // job_company_name: values?.job_company_name,
      phone_numbers: values?.phone_numbers,
      emails: values?.emails,
    };
    const paramsUpdate = {
      elastic_id: id,
      // first_name: values?.first_name || "",
      // last_name: values?.last_name || "",
      // linkedin_url: values?.linkedin_url || "",
      // job_title: values?.job_title || "",
      // job_company_name: values?.job_company_name || "",
      phone_numbers: values?.phone_numbers || "",
      emails: values?.emails || "",
    };
    try {
      console.log(id,"id...")
      if (id) {
        // If "id" exists, it means we are updating the user
        const response = await post(
          `${API_URL}/admin/people-update`,
          paramsUpdate
          );
        if (response.status === 200) {
          // If the request is successful (status code 200), show a success toast
          toast.success(response.data.data || "Password Updated Successfully");
          history.push("/app/people");
        } else {
          // If there's an error in the API response, show an error toast with the response data message
          toast.error(
            response?.data ||
              "Failed to update password. Please try again later."
          );
        }
      } else {
        // If "id" does not exist, it means we are creating a new user
        const response = await post(`${API_URL}/admin/people-add`, params);
        if (response.status === 200) {
          // If the request is successful (status code 200), show a success toast
          toast.success("People Added Successfully");
          history.push("/app/people");
        } else {
          // If there's an error in the API response, show an error toast with the response data message
          toast.error(
            response?.data || "Failed to add people. Please try again later."
          );
        }
      }
    } catch (error) {
      // Handle errors if the API call fails
      toast.error(
        error.response.data.data
          ? error.response.data.data
          : "An error occurred. Please try again later."
      );
    }
  };


  return (
    <Box>
      <PageTitle title={id ? "Update People" : "Create People"} />

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item lg={12} sm={12} xs={12}>
            <AccountSetting loading={loading} formik={formik} />
            <Grid item lg={12} sm={12} xs={12} container gap={5}>
              <Grid item lg={2} sm={2} xs={2} container>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.push("/app/people");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item lg={2} sm={2} xs={2} container>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
