import React from "react";
import {
    Card,
    Grid,
    Typography
} from "@mui/material";
import Input from "components/Input";
import PropTypes from "prop-types";



AccountSetting.propTypes = {
    formik: PropTypes.object
};

export default function AccountSetting(props) {
    const { formik } = props;


    return (
        <Card style={{ position: "relative" }} sx={{ mb: 3 }}>
            <Typography variant='h5' sx={{ mb: 4 }}>{"Creative AI"}</Typography>
            {<Grid container spacing={3} >

                <Grid item lg={6} xs={12}>
                    <Input
                        label="Type*"
                        type="text"
                        name="type"
                        value={formik.values.type}
                        onChange={formik.handleChange}
                        error={formik.touched.type && Boolean(formik.errors.type)}
                        helpertext={formik.touched.type && formik.errors.type}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Subtype*"
                        type="text"
                        name="subtype"
                        value={formik.values.subtype}
                        onChange={formik.handleChange}
                        error={formik.touched.subtype && Boolean(formik.errors.subtype)}
                        helpertext={formik.touched.subtype && formik.errors.subtype}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Language*"
                        type="text"
                        name='language'
                        value={formik.values.language}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.language) && Boolean(formik.errors.language)}
                        helpertext={formik.touched.language && formik.errors.language}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Variants*"
                        type="text"
                        name='varients'
                        value={formik.values.varients}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.varients) && Boolean(formik.errors.varients)}
                        helpertext={formik.touched.varients && formik.errors.varients}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Prompt*"
                        type="text"
                        name='prompts'
                        multiline
                        maxRows={4}
                        value={formik.values.prompts}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.prompts) && Boolean(formik.errors.prompts)}
                        helpertext={formik.touched.prompts && formik.errors.prompts}
                    />
                </Grid>
            </Grid>}


        </Card >
    )
}