import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import Input from "components/Input";
import PropTypes from "prop-types";
import { PhotoCamera } from "@mui/icons-material";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SelectField from "components/SelectField";

AccountSetting.propTypes = {
  formik: PropTypes.object,
};

export default function AccountSetting(props) {
  const [selectedImage, setSelectedImage] = useState(null);
  const { id } = useParams();
  const { formik, categoryImg, category } = props;

  useEffect(() => {
    if (categoryImg instanceof File) {
      formik.setFieldTouched("categoryImg", false);
      formik.setFieldValue("categoryImg", categoryImg);
      const imageURL = URL.createObjectURL(categoryImg);
      setSelectedImage(imageURL);
    } else if (typeof categoryImg === "string") {
      setSelectedImage(categoryImg);
    }
    // eslint-disable-next-line
  }, [categoryImg]);

  const handleImageChange = (e) => {
    const fileInput = e.target;
    const fileName = fileInput.value;
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

    if (!allowedExtensions.exec(fileName)) {
      alert(
        "Please select a valid image file with .jpg, .jpeg, or .png extension."
      );
      fileInput.value = "";
      return false;
    }
    const file = e.target.files[0];
    if (file) {
      formik.setFieldTouched("categoryImg", false);
      formik.setFieldValue("categoryImg", file);
      const imageURL = URL.createObjectURL(file);
      setSelectedImage(imageURL);
    }
  };

  return (
    <Card style={{ position: "relative" }} sx={{ mb: 3 }}>
      <Typography variant="h5" sx={{ mb: 4 }}>
        {id ? "Update Sub-Category" : "Add Sub-Category"}
      </Typography>
      {
        <Grid container spacing={3}>
          <Grid item lg={6} xs={12}>
            <SelectField
              label="Select Category*"
              name="category_id"
              disabled={id}
              value={formik.values.category_id}
              onChange={formik.handleChange}
              error={
                formik.touched.category_id && Boolean(formik.errors.category_id)
              }
              helpertext={
                formik.touched.category_id && formik.errors.category_id
              }
            >
              {category?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item._id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </SelectField>
          </Grid>

          <Grid item lg={6} xs={12}>
            <Input
              label="Name*"
              type="text"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helpertext={formik.touched.name && formik.errors.name}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <Input
              label="Description*"
              type="text"
              name="description"
              multiline
              maxRows={4}
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                Boolean(formik.touched.description) &&
                Boolean(formik.errors.description)
              }
              helpertext={
                formik.touched.description && formik.errors.description
              }
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Input
              label="Prompt*"
              type="text"
              name="prompts"
              multiline
              maxRows={4}
              value={formik.values.prompts}
              onChange={formik.handleChange}
              error={
                Boolean(formik.touched.prompts) &&
                Boolean(formik.errors.prompts)
              }
              helpertext={formik.touched.prompts && formik.errors.prompts}
            />
          </Grid>

          <Grid item lg={12} xs={12}>
            <Input
              type="file"
              id="image-input"
              accept=".jpg, .jpeg, .png"
              name="categoryImg"
              style={{ display: "none" }}
              onChange={handleImageChange}
              error={
                Boolean(formik.touched.categoryImg) &&
                Boolean(formik.errors.categoryImg)
              }
              helpertext={
                formik.touched.categoryImg && formik.errors.categoryImg
              }
            />

            <Box
              display="flex"
              alignItems="start"
              flexDirection="column"
              textAlign="center"
            >
              <label htmlFor="image-input">
                <IconButton
                  color="primary"
                  aria-label="upload image"
                  component="span"
                >
                  <PhotoCamera fontSize="large" />
                </IconButton>
                <Typography variant="caption">Upload Image*</Typography>
              </label>
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="Selected Preview"
                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                />
              )}
            </Box>
          </Grid>

          <Grid item lg={6} xs={12}>
            {/* Checkbox for isToneShow */}
            <FormControlLabel
              control={
                <Checkbox
                  name="is_tone_show"
                  checked={formik.values.is_tone_show}
                  onChange={formik.handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Tone Show"
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            {/* Checkbox for isLanguageShow */}
            <FormControlLabel
              control={
                <Checkbox
                  name="is_language_show"
                  checked={formik.values.is_language_show}
                  onChange={formik.handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Language Show"
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            {/* Checkbox for isVariantsShow */}
            <FormControlLabel
              control={
                <Checkbox
                  name="is_variants_show"
                  checked={formik.values.is_variants_show}
                  onChange={formik.handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Variants Show"
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            {/* Checkbox for isVariantsShow */}
            <FormControlLabel
              control={
                <Checkbox
                  name="is_description_show"
                  checked={formik.values.is_description_show}
                  onChange={formik.handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Description Show"
            />
          </Grid>
        </Grid>
      }
    </Card>
  );
}
