import React, { useState } from "react";
import { Box, Button, Card, CircularProgress, Grid } from "@mui/material";
import PageTitle from "components/PageTitle";
import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import AccountSetting from "./components/AccountSetting";
import { post } from "utils/api";

const API_URL = process.env.REACT_APP_API_URL;

export default function CreativeAi() {
    const [loading, setLoading] = useState(false)
    const [resData, setResData] = useState("");
    // eslint-disable-next-line

    const validationSchema = yup.object({
        type: yup.string().required("First name is required"),
        subtype: yup.string().required("Last name is required"),
        language: yup.string().required("Linkedin url is required"),
        varients: yup.string().required('Job title is required'),
        prompts: yup.string().required('Job company name is required'),
    });




    const formik = useFormik({
        initialValues: {
            type: "",
            subtype: "",
            language: "",
            varients: "",
            prompts: "",
        },

        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    const handleSubmit = async (values) => {
        setLoading(true);
        const params = {
            type: values?.type,
            sub_type: values?.subtype,
            language: values?.language,
            variants: values?.varients,
            prompt: values?.prompts,
        };

        try {

            // If "id" does not exist, it means we are creating a new user
            const response = await post(`${API_URL}/admin/creative-ai`, params);
            if (response.status === 200) {
                setResData(response?.data?.data);
                // If the request is successful (status code 200), show a success toast
                toast.success("Responce Generated");
                setLoading(false)
            } else {
                // If there's an error in the API response, show an error toast with the response data message
                toast.error(
                    response?.data || "Failed to generate responce. Please try again later."
                );
                setLoading(false)

            }
        } catch (error) {
            // Handle errors if the API call fails
            toast.error(
                error.response.data.data
                    ? error.response.data.data
                    : "An error occurred. Please try again later."
            );
            setLoading(false)

        }
    };

    const handleReset = (formik) => {
        formik.resetForm();
        setResData("");
    };

    return (
        <Box>
            <PageTitle title={"Creative AI"} />

            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={1}>

                    <Grid item lg={12} sm={12} xs={12}>

                        <AccountSetting formik={formik} />
                        <Grid item lg={12} sm={12} xs={12} container gap={5}>
                            <Grid item lg={2} sm={2} xs={2} container>
                                <Button
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                    onClick={() => handleReset(formik)}
                                >
                                    Reset
                                </Button>
                            </Grid>
                            <Grid item lg={2} sm={2} xs={2} container>
                                <Button
                                    fullWidth
                                    disabled={loading}
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    {!loading ? "Submit" :
                                        <CircularProgress style={{ color: "white" }} size={25} />}
                                </Button>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </form>

            {resData !== "" &&
                <div>
                    {resData?.map((item, index) => {
                        const lines = item.split('\n'); // Split the text at each '\n' character
                        return (
                            <Card key={index} style={{ marginTop: 30 }}>
                                <div key={index}>
                                    {lines.map((line, lineIndex) => (
                                        <React.Fragment key={lineIndex}>
                                            {line}
                                            {lineIndex < lines.length - 1 && <br />} {/* Add a <br /> if not the last line */}
                                        </React.Fragment>
                                    ))}
                                </div>
                            </Card>
                        );
                    })}
                </div>
            }
        </Box>
    );
}
