import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import PageTitle from "components/PageTitle";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { get, post } from "utils/api";
import AccountSetting from "./AccountSetting";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const API_URL = process.env.REACT_APP_API_URL;

export default function CreateSubCategory() {
  const history = useHistory();
  const [fetchData, setFetchData] = useState();
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  // eslint-disable-next-line

  const getSubCategory = async (id) => {
    if (id) {
      try {
        // If "id" does not exist, it means we are creating a new user
        const response = await get(
          `${API_URL}/admin/get-single-sub-category?sub_category_id=${id}`
        );
        if (response.status === 200) {
          setFetchData(response?.data?.data);
          setLoading(false);
        } else {
          // If there's an error in the API response, show an error toast with the response data message
          toast.error(
            response?.data ||
              "Failed to generate responce. Please try again later."
          );
          setLoading(false);
        }
      } catch (error) {
        // Handle errors if the API call fails
        toast.error(
          error.response.data.data
            ? error.response.data.data
            : "An error occurred. Please try again later."
        );
        setLoading(false);
      }
    }
  };

  const getCategory = async () => {
    const response = await get(
      `${API_URL}/admin/get-all-categories?search_value=&page=1&per_page=10`
    );
    setCategory(response?.data?.data?.Categories);
  };
  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    getSubCategory(id);
  }, [id]);

  const validationSchema = Yup.object({
    category_id: Yup.string().required("Category is required"),
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
    // prompts: Yup.string().required("Prompts is required"),
    // categoryImg: Yup.mixed().required("Image is required"),
  });

  const formik = useFormik({
    initialValues: {
      category_id: fetchData?.category_id || "",
      name: fetchData?.name || "",
      description: fetchData?.description || "",
      prompts: fetchData?.prompts || "",
      categoryImg: fetchData?.icon_url || "",
      is_tone_show: fetchData?.is_tone_show || false,
      is_language_show: fetchData?.is_language_show || false,
      is_variants_show: fetchData?.is_variants_show || false,
      is_description_show: fetchData?.is_description_show || false,
    },

    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();

    if (id) {
      formData.append("category_id", values.category_id);
      formData.append("sub_category_id", id);
      formData.append("name", values.name);
      formData.append("description", values.description);

      formData.append("prompts", values.prompts);

      if (values.categoryImg instanceof File) {
        formData.append("icon_image", values.categoryImg);
      } else {
        // If it hasn't been changed, send an empty string
        formData.append("icon_image", "");
      }
      formData.append("is_tone_show", values.is_tone_show);
      formData.append("is_language_show", values.is_language_show);
      formData.append("is_variants_show", values.is_variants_show);
      formData.append("is_description_show", values.is_description_show);
    } else {
      // Append the fields to the FormData object
      // formData.append("category_id ", category_id)
      formData.append("category_id", values.category_id);
      formData.append("name", values.name);
      formData.append("description", values.description);
      if (values.prompts) {
        formData.append("prompts", values.prompts);
      }
      formData.append("icon_image", values.categoryImg);
      formData.append("is_tone_show", values.is_tone_show);
      formData.append("is_language_show", values.is_language_show);
      formData.append("is_variants_show", values.is_variants_show);
      formData.append("is_description_show", values.is_description_show);
    }

    try {
      if (id) {
        // If "id" does not exist, it means we are creating a new user
        const response = await post(
          `${API_URL}/admin/update-sub-category`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 200) {
          // If the request is successful (status code 200), show a success toast
          toast.success("Updated Successfully...");
          history.push("/app/subcategory");
          setLoading(false);
        } else {
          // If there's an error in the API response, show an error toast with the response data message
          toast.error(
            response?.data ||
              "Failed to generate responce. Please try again later."
          );
          setLoading(false);
        }
      } else {
        // If "id" does not exist, it means we are creating a new user
        const response = await post(
          `${API_URL}/admin/sub-categories`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 200) {
          // If the request is successful (status code 200), show a success toast
          toast.success("SubCategory Added Successfully...");
          history.push("/app/subcategory");
          setLoading(false);
        } else {
          // If there's an error in the API response, show an error toast with the response data message
          toast.error(
            response?.data ||
              "Failed to generate responce. Please try again later."
          );
          setLoading(false);
        }
      }
    } catch (error) {
      // Handle errors if the API call fails
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "An error occurred. Please try again later."
      );
      setLoading(false);
    }
  };

  return (
    <Box>
      <PageTitle title={id ? "Update Sub-Category" : "Add Sub-Category"} />

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item lg={12} sm={12} xs={12}>
            <AccountSetting
              formik={formik}
              categoryImg={formik.values.categoryImg}
              category={category}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
            <Grid item lg={12} sm={12} xs={12} container gap={5}>
              <Grid item lg={2} sm={2} xs={2} container>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.push("/app/subcategory");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item lg={2} sm={2} xs={2} container>
                <Button
                  fullWidth
                  disabled={loading}
                  size="large"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {!loading ? (
                    "Submit"
                  ) : (
                    <CircularProgress style={{ color: "white" }} size={25} />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
