import React from "react";
import {
    Button,
    Card,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import Input from "components/Input";
import PropTypes from "prop-types";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';



AccountSetting.propTypes = {
    formik: PropTypes.object
};

export default function AccountSetting(props) {
    const { formik, loading } = props;

    const { id } = useParams();

    const addEmailField = (formik) => {
        const newEmailData = [...formik.values.emails, {
            address: "",
            type: "personal"
        }];
        formik.setFieldValue('emails', newEmailData)
    };
    const deleteEmailField = (index) => {
        const updatedEmails = [...formik.values.emails];
        updatedEmails.splice(index, 1); // Remove the email field at the specified index
        formik.setFieldValue('emails', updatedEmails); // Update formik values
    };

    const addPhoneField = (formik) => {
        const newPhoneData = [...formik.values.phone_numbers, ""];
        formik.setFieldValue('phone_numbers', newPhoneData);
    };
    const deletePhoneField = (index) => {
        const updatedPhone = [...formik.values.phone_numbers];
        updatedPhone.splice(index, 1); // Remove the email field at the specified index
        formik.setFieldValue('phone_numbers', updatedPhone); // Update formik values
    };

    return (
        <Card style={{ position: "relative" }} sx={{ mb: 3 }}>
            <Typography variant='h5' sx={{ mb: 4 }}>{id ? "Update People" : "Add People"}</Typography>
            {!loading && <Grid container spacing={3} >

                {/* <Grid item lg={6} xs={12}>
                    <Input
                        label="First Name*"
                        type="text"
                        name="first_name"
                        value={formik.values.first_name}
                        onChange={formik.handleChange}
                        error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                        helpertext={formik.touched.first_name && formik.errors.first_name}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Last Name*"
                        type="text"
                        name="last_name"
                        value={formik.values.last_name}
                        onChange={formik.handleChange}
                        error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                        helpertext={formik.touched.last_name && formik.errors.last_name}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="LinkedIn URL"
                        type="text"
                        name='linkedin_url'
                        value={formik.values.linkedin_url}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.linkedin_url) && Boolean(formik.errors.linkedin_url)}
                        helpertext={formik.touched.linkedin_url && formik.errors.linkedin_url}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Job Title"
                        type="text"
                        name='job_title'
                        value={formik.values.job_title}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.job_title) && Boolean(formik.errors.job_title)}
                        helpertext={formik.touched.job_title && formik.errors.job_title}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Input
                        label="Company Name"
                        type="text"
                        name='job_company_name'
                        value={formik.values.job_company_name}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.job_company_name) && Boolean(formik.errors.job_company_name)}
                        helpertext={formik.touched.job_company_name && formik.errors.job_company_name}
                    />
                </Grid> */}
                <Grid container spacing={3} item style={{ display: 'flex', alignItems: 'center' }}>
                    {formik?.values?.phone_numbers?.map((_, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Grid container item lg={4} xs={4} >
                                    <Input
                                        label="phone*"
                                        type="phone"
                                        name={`phone_numbers[${index}]`}
                                        // disabled={id ? true : false}
                                        value={formik.values.phone_numbers[index]}
                                        onChange={(e) => formik.handleChange(e)}
                                        error={Boolean(formik.touched.phone_numbers?.[index]) && Boolean(formik.errors.phone_numbers?.[index])}
                                        helpertext={formik.touched.phone_numbers?.[index] && formik.errors.phone_numbers?.[index]}
                                    />
                                </Grid>
                                {
                                    index !== 0 &&
                                    <Grid item lg={2} xs={2}>
                                        <Button onClick={() => deletePhoneField(index)}><DeleteIcon /></Button>
                                    </Grid>
                                }
                                {
                                    index === 0 &&
                                    <Grid item lg={2} xs={2}>

                                    </Grid>
                                }
                            </React.Fragment>
                        )
                    })
                    }
                </Grid>
                <Grid item lg={12} xs={12}>
                    <Button variant="outlined" onClick={() => addPhoneField(formik)}><AddIcon /></Button>
                </Grid>

                {formik?.values?.emails?.map((_, index) => {
                    return (
                        <Grid key={index} container spacing={3} item style={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item lg={5} xs={10}>
                                <InputLabel>Email Type</InputLabel>
                                <FormControl fullWidth >
                                    <Select
                                        name={`emails[${index}].type`}
                                        value={formik.values.emails[index].type}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.touched.emails?.[index]?.type) && Boolean(formik.errors.emails?.[index]?.type)}
                                        helpertext={formik.touched.emails?.[index]?.type && formik.errors.emails?.[index]?.type}
                                    >
                                        <MenuItem value="personal">Personal</MenuItem>
                                        <MenuItem value="professional">Professional</MenuItem>
                                        <MenuItem value="current_professional">Current Professional</MenuItem>
                                        <MenuItem value="work_email">Work</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item lg={5} xs={10}>
                                <Input
                                    label="email*"
                                    type="email"
                                    name={`emails[${index}].address`}
                                    // disabled={id ? true : false}
                                    value={formik.values.emails[index].address}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.emails?.[index]?.address) && Boolean(formik.errors.emails?.[index]?.address)}
                                    helpertext={formik.touched.emails?.[index]?.address && formik.errors.emails?.[index]?.address}

                                />
                            </Grid>
                            {
                                index !== 0 && <Grid item lg={2} xs={2}>
                                    <Button onClick={() => deleteEmailField(index)}><DeleteIcon /></Button>
                                </Grid>
                            }
                        </Grid>
                    )
                })
                }
                <Grid item lg={12} xs={12}>
                    <Button variant="outlined" onClick={() => addEmailField(formik)}><AddIcon /></Button>
                </Grid>
            </Grid>}

            {loading && <div className="" style={{ height: "50vh", display: 'flex', justifyContent: "center", alignItems: "center" }}>


                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>


            </div>}
        </Card >
    )
}