import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Grid } from "@mui/material";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import FloatingButton from "components/FloatingButton";
import { del, get } from "utils/api";
import PageTitle from "components/PageTitle";
import { toast } from "react-toastify";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import CheckIcon from "@mui/icons-material/Check";
import Model from "./Model";
import NoData from "./NoData";

const API_URL = process.env.REACT_APP_API_URL;

const Plan = () => {
  const history = useHistory();
  const { id } = useParams();
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const handleDelete = async (id) => {
    setLoading(true);
    const res = await del(`${API_URL}/admin/plan-price/${id}`);
    setLoading(false);
    setOpen(false);
    toast.success(res?.data?.message);
    getPayments();
  };
  const getPayments = async () => {
    setLoading(true);
    const response = await get(`${API_URL}/admin/plan-price/${id}`);
    setResponseData(response?.data?.data);
    setLoading(false);
  };

  useEffect(() => {
    getPayments();
  }, []);

  const handleClick = (idx) => {
    history.push(`/app/payments/plan/${id}/subscription`, { itemId: idx });
  };

  return (
    <div>
      <div style={{ marginLeft: "20px" }}>
        <div style={{ display: "flex" }}>
          <div
            onClick={() => {
              history.push("/app/payments");
            }}
            style={{ cursor: "pointer" }}
          >
            <ArrowBackSharpIcon />
          </div>
          <div style={{ marginLeft: 17 }}>
            <PageTitle title="Plan Price" />
          </div>
        </div>
      </div>
      <Grid container spacing={1}>
        {loading ? (
          <div style={{ margin: "auto" }}>
            <CircularProgress size={25} />
          </div>
        ) : responseData !== undefined && responseData?.length !== 0 ? (
          responseData?.map((item, idx) => {
            return (
              <Grid key={idx} item xs={12} md={6} lg={4}>
                <div
                  style={{
                    height: "480px",
                    backgroundColor: "#fff",
                    color: "white",
                    padding: "20px",
                    borderRadius: "10px",
                    margin: "20px",
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div>
                    <div
                      style={{
                        textAlign: "center",
                        color: "#000",
                        cursor: "pointer",
                        fontSize: "20px",
                        marginTop: "20px",
                        textTransform: "capitalize",
                      }}
                    >
                      {item?.period_unit}
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        color: "#000",
                        fontWeight: "bold",
                        cursor: "pointer",
                        fontSize: "40px",
                        marginTop: "10px",
                      }}
                    >
                      €{item?.price}
                    </div>
                    <div
                      style={{
                        textTransform: "capitalize",
                        textAlign: "center",
                        color: "#000",
                        cursor: "pointer",
                        fontSize: "20px",
                        marginTop: "10px",
                      }}
                    >
                      Per {item?.period_unit}
                    </div>
                    <div style={{ color: "#000" }}>
                      <p
                        style={{
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {item?.plan_type}
                      </p>
                      <div
                        style={{
                          textAlign: "start",
                        }}
                      >
                        <div style={{ display: "flex", gap: "10px" }}>
                          <div>
                            <CheckIcon />
                          </div>
                          <div>{item?.super_credits} Super Credits</div>
                        </div>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <div>
                            <CheckIcon />
                          </div>
                          <div>{item?.search_credits} Search Credits</div>
                        </div>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <div>
                            <CheckIcon />
                          </div>{" "}
                          <div> {item?.basic_credits} Basic Credits</div>
                        </div>
                        {/* <div style={{ display: "flex", gap: "10px" }}>
                          <div>
                            {" "}
                            <CheckIcon />{" "}
                          </div>{" "}
                          <div>{item?.creative_credits} Creative</div>
                          Credits
                        </div> */}
                        {item?.linkedin_extension && (
                          <div style={{ display: "flex", gap: "10px" }}>
                            <div>
                              <CheckIcon />
                            </div>
                            <div>LinkedIn Extension</div>
                          </div>
                        )}
                        {item?.crm_integration && (
                          <div style={{ display: "flex", gap: "10px" }}>
                            <div>
                              <CheckIcon />
                            </div>
                            <div>CRM Integration</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                      marginTop: "auto",
                    }}
                  >
                    <Button
                      onClick={() => handleClick(item?._id)}
                      style={{ width: "100%", height: "40px" }}
                      component="label"
                      variant="contained"
                    >
                      Update
                    </Button>
                    {/* <Button
                      onClick={() => {
                        setDeleteId(item?._id);
                        setOpen(true);
                      }}
                      color="error"
                      style={{ width: "100%", height: "40px" }}
                      component="label"
                      variant="contained"
                    >
                      {!loading ? (
                        "Delete"
                      ) : (
                        <CircularProgress
                          style={{ color: "white" }}
                          size={25}
                        />
                      )}
                    </Button> */}
                  </div>
                </div>
              </Grid>
            );
          })
        ) : (
          <>
            <NoData />
          </>
        )}
      </Grid>

      <Model
        open={open}
        setOpen={setOpen}
        handleDelete={handleDelete}
        deleteId={deleteId}
        loading={loading}
      />
      <FloatingButton
        onClick={() => history.push(`/app/payments/plan/${id}/subscription`)}
      />
    </div>
  );
};

export default Plan;
