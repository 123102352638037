import {
  Box,
  Button,
  Card,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Input from "components/Input";
import React, { useEffect, useState } from "react";
import { get } from "utils/api";
import styled from "@emotion/styled";

const API_URL = process.env.REACT_APP_API_URL;

const FilterBox = styled(Box)(() => ({
  width: "100%",
  marginTop: 30,
  marginBottom: 20,
  display: "flex",
  justifyContent: "space-between",
}));

const RedeemCode = () => {
  const [responseData, setResponseData] = useState([]);
  console.log(responseData, "responseData...");
  const [loading, setLoading] = useState(false);
  const totalCount = responseData?.total_results || 0;
  const pageCount = Math.ceil(totalCount / 10);
  const [filter, setFilter] = useState({
    keyword: "",
    page: 1,
  });

  const getPaymentList = async () => {
    setLoading(true);
    const response = await get(`${API_URL}/admin/promotions/`);
    setResponseData(response?.data);
    setLoading(false);
  };

  useEffect(() => {
    getPaymentList();
  }, [filter]);

  const handleChangeFilter = ({ target }) => {
    const { name, value } = target;

    setFilter({
      ...filter,
      [name]: value,
      page: 1,
    });
  };

  const handleChangePagination = (e, page) => {
    setFilter({
      ...filter,
      page: page,
    });
  };

  return (
    <Card>
      <Typography variant="h5" sx={{ fontWeight: 600 }}>
        Redeem Code
      </Typography>

      <FilterBox>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item lg={6} sm={12} xs={12}>
            <Input
              label="Search"
              name="keyword"
              placeholder="Search"
              value={filter.keyword ?? ""}
              onChange={handleChangeFilter}
            />
          </Grid>
        </Grid>
      </FilterBox>

      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Promo Code</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Subscription Name</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {responseData?.total_results === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={7}>
                  No Data
                </TableCell>
              </TableRow>
            )}
            {responseData?.data?.map((item, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{item?.promo_code || "-"}</TableCell>
                <TableCell>{item?.user_email || "-"}</TableCell>
                <TableCell>{item?.subscription_name || "-"}</TableCell>
                <TableCell>
                  {item?.is_active === true ? "Active" : "In active" || "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Pagination
          sx={{ mt: 1 }}
          page={filter?.page}
          count={pageCount}
          onChange={handleChangePagination}
        />
      </Box>
    </Card>
  );
};

export default RedeemCode;
