import React, { useEffect, useState } from "react";
import { Grid, useTheme } from "@mui/material";
import Widget from "./Widget";
import { People } from "@mui/icons-material";
import PersonSharpIcon from "@mui/icons-material/PersonSharp";
import PropTypes from "prop-types";
import { get } from "utils/api";
const API_URL = process.env.REACT_APP_API_URL;

Widgets.propTypes = {
  countUser: PropTypes.number,
  widget: PropTypes.any,
};

export default function Widgets() {
  const [loading, setLoading] = useState();
  const [responseData, setResponseData] = useState({
    totalUser: 0,
    total_people: 0,
  });
  const theme = useTheme();

  useEffect(() => {
    getTotalUser();
  }, []);

  const getTotalUser = async () => {
    setLoading(true);
    try {
      const response = await get(`${API_URL}/admin/dashboard`);
      const totalUser = response.data.data.total_user_count;
      const total_people = response.data.data.total_people_count;
      setResponseData({ totalUser, total_people });
      //   settotalUser(totalUser);
      //   setProxycurlCredits(totalUser, total_people);
    } catch (error) {
      // Handle any errors that occurred during the API call
      console.error("Error fetching total user count:", error);
      // You might want to display an error message to the user or take other actions
    }
    setLoading(false);
  };

  return (
    <Grid container spacing={3} sx={{ mb: 3 }}>
      <Grid item lg={6} sm={12} xs={12}>
        <Widget
          title="Total Users"
          content={
            loading ? (
              <p style={{ fontSize: "18px", fontWeight: "bold" }}>Loading...</p>
            ) : (
              responseData?.totalUser || 0
            )
          }
          icon={
            <People sx={{ color: theme.palette.primary.main, fontSize: 62 }} />
          }
        />
      </Grid>
      {/* )} */}
      <Grid item lg={6} sm={12} xs={12}>
        <Widget
          title="Total People"
          content={
            loading ? (
              <p style={{ fontSize: "18px", fontWeight: "bold" }}>Loading...</p>
            ) : (
              responseData.total_people || 0
            )
          }
          icon={
            <PersonSharpIcon
              sx={{ color: theme.palette.primary.main, fontSize: 62 }}
            />
          }
        />
      </Grid>
    </Grid>
  );
}
