import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import { post } from "utils/api";
const API_URL = process.env.REACT_APP_API_URL;

const Charts = () => {
  const [select, setSelect] = useState("day");
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState([]);

  const handleChange = async (event) => {
    const value = event.target.value;
    setSelect(value);
  };

  useEffect(() => {
    handleSubmit(select);
  }, [select]);

  const handleSubmit = async (select) => {
    setLoading(true);
    const body = {
      date_name: select || "",
    };

    try {
      const response = await post(`${API_URL}/admin/analysis-dashboard`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        setApiData(response?.data?.data);
        toast.success("Updated Successfully...");
      } else {
        toast.error(
          response?.data ||
            "Failed to generate response. Please try again later."
        );
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "An error occurred. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <FormControl sx={{ minWidth: 120 }} size="small">
          <InputLabel
            id="demo-select-small-label"
            style={{ display: "flex", paddingTop: "5px" }}
          >
            Select
          </InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={select}
            label="Select"
            onChange={handleChange}
          >
            <MenuItem value="day">Day</MenuItem>
            <MenuItem value="month">Month</MenuItem>
            <MenuItem value="year">Year</MenuItem>
          </Select>
        </FormControl>
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "40vh",
            justifyContent: "center",
          }}
        >
          Loading...
        </div>
      ) : (
        <ResponsiveContainer width="100%" height={500}>
          <LineChart
            data={apiData}
            margin={{ top: 40, right: 30, left: 20, bottom: 40 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tick={{ angle: -40, textAnchor: "end" }} />
            <YAxis />
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
            <Line
              type="monotone"
              dataKey="enrich"
              stroke="#31A6E8"
              activeDot={{ r: 8 }}
            />
            <Line
              type="monotone"
              dataKey="email"
              stroke="#D444F1"
              activeDot={{ r: 8 }}
            />
            <Line
              type="monotone"
              dataKey="phone"
              stroke="#FF692E"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="search" stroke="#5925DC" activeDot={{ r: 8 }}/>
          </LineChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default Charts;
