import React from "react";
import { Dashboard, Payments, Settings } from "@mui/icons-material";
import BugReportIcon from "@mui/icons-material/BugReport";
import FeedbackIcon from "@mui/icons-material/Feedback";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import FormatShapesIcon from "@mui/icons-material/FormatShapes";
import { actions } from "../../constants/permission";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DifferenceIcon from "@mui/icons-material/Difference";
import PaymentSharpIcon from '@mui/icons-material/PaymentSharp';
import HubSharpIcon from '@mui/icons-material/HubSharp';
import PaidSharpIcon from '@mui/icons-material/PaidSharp';
import { MdRedeem } from "react-icons/md";

const Menus = [
  {
    name: "Dashboard",
    icon: <Dashboard />,
    path: "/app/dashboard",
    act: actions.readAll,
    feat: "All",
  },
  {
    name: "People",
    icon: <PeopleIcon />,
    path: "/app/people",
    act: actions.readAll,
    feat: "All",
  },
  {
    name: "Users",
    icon: <PersonIcon />,
    path: "/app/user",
    act: actions.readAll,
    feat: "All",
  },
  // {
  //   name: "Activity Log",
  //   icon: <RestorePageIcon />,
  //   path: "/app/active-log",
  //   act: actions.readAll,
  //   feat: "All",
  // },
  {
    name: "Creative AI",
    icon: <FormatShapesIcon />,
    path: "/app/creative-ai",
    act: actions.readAll,
    feat: "All",
  },
  {
    name: "Category",
    icon: <FileCopyIcon />,
    path: "/app/category",
    act: actions.readAll,
    feat: "All",
  },
  {
    name: "SubCategory",
    icon: <DifferenceIcon />,
    path: "/app/subcategory",
    act: actions.readAll,
    feat: "All",
  },
  {
    name: "Feedback",
    icon: <FeedbackIcon />,
    path: "/app/feedback",
    act: actions.readAll,
    feat: "All",
  },
  {
    name: "Bug",
    icon: <BugReportIcon />,
    path: "/app/bug",
    act: actions.readAll,
    feat: "All",
  },
  {
    name: "Log",
    icon: <HubSharpIcon />,
    path: "/app/log",
    act: actions.readAll,
    feat: "All",
  },
  {
    name: "Customer Feedback",
    icon: <HubSharpIcon />,
    path: "/app/customer-feedback",
    act: actions.readAll,
    feat: "All",
  },
  {
    name: "Plan",
    icon: <Payments />,
    path: "/app/payments",
    act: actions.readAll,
    feat: "All",
  },
  // {
  //   name: "Prizing Card",
  //   icon: <PaymentSharpIcon/>, 
  //   path: "/app/palncard",
  //   act: actions.readAll,
  //   feat: "All",
  // },
  {
    name: "Payment List",
    icon: <PaidSharpIcon/>,
    path: "/app/payment-list",
    act: actions.readAll,
    feat: "All",
  },
  // {
  //   name: "Redeem Code",
  //   icon: <MdRedeem size={22}/>,
  //   path: "/app/redeem-code",
  //   act: actions.readAll,
  //   feat: "All",
  // },
  {
    name: "Setting",
    icon: <Settings />,
    path: "/app/setting",
    act: actions.readAll,
    feat: "All",
  },
];

export default Menus;
