import React, { useState } from "react";
import AccountSetting from "./AccountSetting";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import PageTitle from "components/PageTitle";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useFormik } from "formik";
import { post } from "utils/api";
import { toast } from "react-toastify";
import * as Yup from "yup";

const API_URL = process.env.REACT_APP_API_URL;

const CreatePlan = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState("");

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    const params = {
      plan_name: values?.name.split(" ").join(""),
      description: values?.description,
    };

    try {
      // If "id" does not exist, it means we are creating a new user
      const response = await post(`${API_URL}/admin/plan `, params);
      if (response.status === 200) {
        setResData(response?.data?.data);
        // If the request is successful (status code 200), show a success toast
        toast.success(response?.data?.message);
        setLoading(false);
        history.push("/app/payments");
      } else {
        // If there's an error in the API response, show an error toast with the response data message
        toast.error(
          response?.data?.message ||
            "Failed to add plan Please try again later."
        );
        setLoading(false);
      }
    } catch (error) {
      // Handle errors if the API call fails
      toast.error(
        error.response.data.data
          ? error.response.data.data
          : "An error occurred. Please try again later."
      );
      setLoading(false);
    }
  };

  return (
    <Box>
      <PageTitle title="Add Plan" />
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item lg={12} sm={12} xs={12}>
            <AccountSetting formik={formik} />

            <Grid item lg={12} sm={12} xs={12} container gap={5}>
              <Grid item lg={2} sm={2} xs={2} container>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.push("/app/payments");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item lg={2} sm={2} xs={2} container>
                <Button
                  fullWidth
                  // disabled={loading}
                  size="large"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {!loading ? (
                    "Submit"
                  ) : (
                    <CircularProgress style={{ color: "white" }} size={25} />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default CreatePlan;
