import { createSelector } from "@reduxjs/toolkit";

const peopleSelector = (state) => state.people;

const getPeoples = () => createSelector(
    peopleSelector,
    people => people?.peoples
);

const getPagination = () => createSelector(
    peopleSelector,
    people => people?.pagination
);

const getPeopleById = () => createSelector(
    peopleSelector,
    people => people.people
);

const submitPeople = () => createSelector(
    peopleSelector,
    people => {
        if (people.submit.success) {
            return {
                success: true,
                message: people.submit.success
            }
        }

        return {
            success: false,
            message: people.submit.error
        }
    }
)

const deletePeople = () => createSelector(
    peopleSelector,
    people => {
        if (people.delete.success) {
            return {
                success: true,
                message: people.delete.success
            }
        }

        return {
            success: false,
            message: people.delete.error
        }
    }
)

const profile = () => createSelector(
    peopleSelector,
    people => people?.profile
)

export const PeopleSelector = {
    getPeoples,
    getPagination,
    getPeopleById,
    submitPeople,
    deletePeople,
    profile
}