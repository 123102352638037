import React from "react";
import { Box, Grid } from "@mui/material";
import styled from "@emotion/styled";
import Input from "components/Input";
import PropTypes from "prop-types";

const FilterBox = styled(Box)(() => ({
    width: "100%",
    marginTop: 30,
    marginBottom: 20,
    display: "flex",
    justifyContent: "space-between"
}));

Filter.propTypes = {
    filter: PropTypes.object,
    onChange: PropTypes.func
};

export default function Filter(props) {
    const { filter, onChange } = props;


    return (
        <FilterBox>
            <Grid container spacing={3} justifyContent="space-between">
                <Grid item lg={12} sm={12} xs={12} container justifyContent='space-between'>
                    <Grid item lg={6} sm={12} xs={12}>
                        <Input
                            fullWidth
                            size='small'
                            label="Search"
                            placeholder='Search'
                            value={filter.keyword ?? ""}
                            name="keyword"
                            onChange={onChange} />
                    </Grid>

                </Grid>

            </Grid>
        </FilterBox>
    )
}