import React, { useEffect, useState } from "react";
import { del, get } from "utils/api";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  Pagination,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import FloatingButton from "components/FloatingButton";
import { useHistory } from "react-router-dom";
import DialogConfirm from "components/DialogConfirm";
import ListSkeleton from "../../components/Skeleton/ListSkeleton";
import { CleaningServices, Edit } from "@mui/icons-material";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL;

const Category = () => {
  const history = useHistory();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(true);
  const totalCount = responseData?.total_records || 0;
  const pageCount = Math.ceil(totalCount / 10);
console.log(selectedId,"selectedId.....");
  const [filter, setFilter] = useState({
    keyword: "",
    page: 1,
  });

  // const handleChangeFilter = async ({ target }) => {
  //     const { name, value } = target;
  //     const params = {
  //         ...filter,
  //         keyword: value,
  //         page: 1
  //     };

  //     if (value === -1) {
  //         delete params[name];
  //     }

  //     setFilter(params);
  // };

  const handleChangePagination = (e, val) => {
    setFilter({
      ...filter,
      page: val,
    });
  };

  const getCategory = async () => {
    setLoading(true);
    const response = await get(
      `${API_URL}/admin/get-all-categories?search_value=${filter.keyword}&page=${filter.page}&per_page=10`
    );
    setResponseData(response?.data?.data);
    setLoading(false);
  };
  useEffect(() => {
    getCategory();
    // eslint-disable-next-line
  }, [filter]);

  const handleDelete = async () => {
    const response = await del(
      `${API_URL}/admin/category?category_id=${selectedId}`
    );
    if (response.status === 200) {
      // If the request is successful (status code 200), show a success toast
      toast.success("Deleted Successfully...");
      if (responseData.current_page === responseData.total_pages) {
        const total_page_record =
          responseData.total_records - (responseData.total_pages - 1) * 10;
        if (total_page_record <= 1) {
          let setPage = 1;
          if (responseData.current_page > 1) {
            setPage = responseData.current_page - 1;
          }
          setFilter({
            ...filter,
            page: setPage,
          });
        } else {
          getCategory();
        }
      } else {
        getCategory();
      }
      setConfirmDelete(false);
    } else {
      // If there's an error in the API response, show an error toast with the response data message
      toast.error(
        response?.response?.data?.message ||
          "Failed to delete. Please try again later."
      );
      setConfirmDelete(false);
    }
  };

  return (
    <Card>
      <Typography variant="h5" sx={{ fontWeight: 600 }}>
        Category
      </Typography>
      {loading ? (
        <ListSkeleton />
      ) : (
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Icon Image</TableCell>
                <TableCell align="right">Option</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {responseData?.total_records === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    No Data
                  </TableCell>
                </TableRow>
              )}
              {responseData?.Categories?.map((item, i) => {
                console.log(item,"item...   ")
                return (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{item.name || "-"}</TableCell>
                    <TableCell>{item.description || "-"}</TableCell>
                    <TableCell>
                      {item.icon_url ? (
                        <img
                          style={{
                            height: "50px",
                            width: "100px",
                            objectFit: "contain",
                          }}
                          src={item.icon_url}
                          alt="Icon"
                          height="50"
                        />
                      ) : (
                        "-"
                      )}
                    </TableCell>

                    <TableCell align="right">
                      <IconButton
                        onClick={() =>
                          history.push(`/app/category/update/${item.id}`)
                        }
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setSelectedId(item.id);
                          setConfirmDelete(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <Pagination
            sx={{ mt: 1 }}
            page={filter?.page}
            count={pageCount}
            onChange={handleChangePagination}
          />
        </Box>
      )}

      {/* <FloatingButton onClick={() => history.push("/app/people/create")} /> */}

      <DialogConfirm
        title="Delete Data"
        content="Are you sure want to delete this data?"
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onSubmit={() => handleDelete()}
      />

      <FloatingButton onClick={() => history.push("/app/category/create")} />
    </Card>
  );
};

export default Category;
